import { Layout } from 'antd';
import React from 'react';

import Login from "./Login";

const { Header, Content, Footer} = Layout;

const App: React.FC = function () {
  return (
  <Layout>
    {/* <Header>登录系统</Header> */}
    <Layout>
      <Content style={{
        height: '90vh',
        backgroundColor: '#fff',
        paddingTop: '20vh',
      }}>
        <div style={{
          width: '70%',
        }}><Login /></div></Content>
    </Layout>
    <Footer>
      <p style={{
        textAlign: 'center',
      }}><a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备15051092号-1</a></p>
    </Footer>
  </Layout>
  );
}

export default App;
